import * as React from 'react'
import { Link } from "gatsby"
import Layout from '/src/components/layout'

const ListaPage = () => {
    return (
        <Layout pageTitle="Lista">
            
            <p className="intro">
                Just nu är prio att samla in och kvalificera data för att visa den i kartan.
            </p>
            <p style={{textAlign: 'center'}}>
                Framöver kommer det även vara möjligt att via lista och filtering hitta laddstationer.<br/>
                Vad vill du se? Skriv till <Link to="/om/forslag">Laddify</Link> i sociala medier.
            </p>

        </Layout>
    )
}

export default ListaPage